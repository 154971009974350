import moment from 'moment'
moment.locale('es')

const DATE_MODIFY = {
  year: 2023,
  month: 5,
  date: 1,
  hour: '00',
  minute: '00',
  second: '00',
}

const SPENDINGS_COLUMNS = [
  {
    title: 'Fecha (UTC)',
    key: 'date',
    dataIndex: 'created_at',
    width: 240,
    customRender: (value) => moment.utc(value).format('D MMMM, YYYY'),
  },
  {
    title: 'ID de Canal',
    key: 'apiBusinessId',
    dataIndex: 'apiBusinessId',
    type: 'string',
    width: 240,
    scopedSlots: { customRender: 'apiBusinessId' },
  },
  // TODO: Solo hasta que haya soporte para outbound e inbound sessions
  {
    key: 'totalSessions',
    dataIndex: 'totalSessions',
    type: 'string',
    width: 200,
    slots: { title: 'sessionsTitle' },
  },
  // TODO: Se comenta hasta que quede por Back
  // {
  //   title: 'Sesión entrante',
  //   key: 'totalItems',
  //   dataIndex: 'totalItems',
  //   type: 'string',
  //   width: 200,
  // },
  // {
  //   title: 'Sesión saliente',
  //   key: 'totalItems',
  //   dataIndex: 'totalItems',
  //   type: 'string',
  //   width: 200,
  // },
  {
    key: 'totalMessagesFEP',
    dataIndex: 'totalMessagesFEP',
    type: 'string',
    width: 100,
    slots: { title: 'FEPTitle' },
  },
  {
    key: 'totalMessagesFTC',
    dataIndex: 'totalMessagesFTC',
    type: 'string',
    width: 100,
    slots: { title: 'FTCTitle' },
  },
  {
    key: 'totalMessagesUIC',
    dataIndex: 'totalMessagesUIC',
    type: 'string',
    width: 100,
    slots: { title: 'UICTitle' },
    isOld: true,
  },
  {
    key: 'totalMessagesCService',
    dataIndex: 'totalMessagesCService',
    type: 'string',
    width: 100,
    title: 'Service',
    isNew: true,
  },
  {
    key: 'totalMessagesBIC',
    dataIndex: 'totalMessagesBIC',
    type: 'string',
    width: 100,
    slots: { title: 'BICTitle' },
    isOld: true,
  },
  {
    key: 'totalMessagesCMkt',
    dataIndex: 'totalMessagesCMkt',
    type: 'string',
    width: 100,
    title: 'MKT',
    isNew: true,
  },
  {
    key: 'totalMessagesCAuth',
    dataIndex: 'totalMessagesCAuth',
    type: 'string',
    width: 150,
    title: 'Authentication',
    isNew: true,
  },
  {
    key: 'totalMessagesCUtil',
    dataIndex: 'totalMessagesCUtil',
    type: 'string',
    width: 100,
    title: 'Utility',
    isNew: true,
  },
  {
    title: 'Cantidad total',
    key: 'totalItems',
    dataIndex: 'totalItems',
    width: 180,
    type: 'number',
  },
  {
    title: 'Costo total',
    key: 'totalPrice',
    dataIndex: 'totalPrice',
    width: 180,
    type: 'number',
  },

  // -----comentado porque aun no se tomara la descarga de los datos
  // {
  //   title: 'Acción',
  //   key: 'action',
  //   scopedSlots: { customRender: 'action' },
  //   width: 200,
  // },
]

const RECHARGE_COLUMNS = [
  {
    title: 'Fecha (UTC)',
    key: 'date',
    dataIndex: 'created_at',
    width: 240,
    customRender: (value) => moment.utc(value).format('D MMMM, YYYY'),
  },
  {
    title: 'Nro de pago',
    dataIndex: 'paycode',
    key: 'paycode',
  },
  {
    title: 'Método',
    dataIndex: 'method',
    key: 'method',
  },
  {
    title: 'Monto',
    dataIndex: 'total',
    key: 'total',
    type: 'number',
  },
  {
    title: 'Estado',
    key: 'state',
    dataIndex: 'state',
    scopedSlots: { customRender: 'state' },
  },
  {
    title: 'Acción',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]

const RECHARGE_SMS_COLUMNS = [
  {
    title: 'Fecha',
    key: 'date',
    dataIndex: 'created_at',
    width: 240,
    customRender: (value) => moment.utc(value).format('D MMMM, YYYY'),
  },
  {
    title: 'Nro de pago',
    dataIndex: 'paycode',
    key: 'paycode',
  },
  {
    title: 'Método',
    dataIndex: 'method',
    key: 'method',
  },
  {
    title: 'Cantidad',
    dataIndex: 'amount',
    key: 'amount',
    type: 'number',
  },
  {
    title: 'Monto',
    dataIndex: 'total',
    key: 'total',
    type: 'number',
  },
  {
    title: 'Estado',
    key: 'state',
    dataIndex: 'state',
    scopedSlots: { customRender: 'state' },
  },
  {
    title: 'Acción',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
]

const TITLES_SPENDINGS_WHATSAPP = {
  sessionsTitle: {
    title: 'Total de sesiones',
    tooltip:
      'Sesión: Cualquier mensaje adicional al inicio de la conversación, dentro de las 24 horas posteriores al inicio de la conversación.',
  },
  FEPTitle: {
    title: 'FEP',
    tooltip:
      'FEP: Free entry point. Estas son conversaciones que inician por un anuncio al que el cliente escribe.',
  },
  FTCTitle: {
    title: 'FTC',
    tooltip:
      'FTC: Free tier conversation. Estas son conversaciones gratuitas iniciadas por una plantilla o por el primer mensaje del cliente a la empresa.',
  },
  BICTitle: {
    title: 'BIC',
    tooltip:
      'Conversaciones iniciadas por la empresa mediante plantillas individuales o campañas.',
  },
  UICTitle: {
    title: 'UIC',
    tooltip: 'Conversaciones iniciadas por el usuario escribiendo al WBA.',
  },
}

const FOOTER_SPENDINGS = [
  {
    title: 'Total',
    key: 'total',
    column: 0,
    width: '240px',
  },
  {
    key: 'channelId',
    column: 1,
    width: '240px',
  },
  {
    key: 'totalAllSessions',
    dataIndex: 'totalAllSessions',
    column: 2,
    width: '200px',
  },
  {
    key: 'totalAllFep',
    dataIndex: 'totalAllFep',
    column: 3,
    width: '100px',
  },
  {
    key: 'totalAllFtc',
    dataIndex: 'totalAllFtc',
    width: '100px',
    column: 4,
  },
  {
    key: 'totalAllUic',
    dataIndex: 'totalAllUic',
    width: '100px',
    column: 5,
    isOld: true,
  },
  {
    key: 'totalAllCatService',
    dataIndex: 'totalAllCatService',
    width: '100px',
    column: 6,
    isNew: true,
  },
  {
    key: 'totalMessagesBIC',
    dataIndex: 'totalAllBic',
    width: '100px',
    column: 7,
    isOld: true,
  },
  {
    key: 'totalMessagesCMkt',
    dataIndex: 'totalAllCatMkt',
    width: '100px',
    column: 8,
    isNew: true,
  },
  {
    key: 'totalAllCatAuth',
    dataIndex: 'totalAllCatAuth',
    width: '150px',
    column: 9,
    isNew: true,
  },
  {
    key: 'totalAllCatUtil',
    dataIndex: 'totalAllCatUtil',
    width: '100px',
    column: 10,
    isNew: true,
  },
  {
    key: 'totalAllQuantity',
    dataIndex: 'totalAllQuantity',
    width: '180px',
    value: '1000',
    column: 11,
  },
  {
    key: 'totalAllPrice',
    dataIndex: 'totalAllPrice',
    width: '180px',
    column: 12,
  },
]

export {
  SPENDINGS_COLUMNS,
  RECHARGE_SMS_COLUMNS,
  RECHARGE_COLUMNS,
  TITLES_SPENDINGS_WHATSAPP,
  FOOTER_SPENDINGS,
  DATE_MODIFY,
}
